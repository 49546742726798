import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Stack } from "@mui/material";
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import "../i18n";
import CardQueryPaper from "./CardQueryPaper";

export default function SearchDeckFormComponent({
  startDate,
  endDate,
  context,
}) {
  const { t, i18n } = useTranslation(["home_page"]);

  const {
    mainDeckType,
    setMainDeckType,
    subDeckType,
    setSubDeckType,
    cardNameQueryList,
    setCardNameQueryList,
  } = useContext(context);

  const [mainDeckTypeOptions, setMainDeckTypeOptions] = useState([]);
  const [subDeckTypeOptions, setSubDeckTypeOptions] = useState([]);

  useEffect(() => {
    const fetchMainDeckType = async () => {
      const params = new URLSearchParams();
      if (startDate) params.append("start_date", startDate.toISOString());
      if (endDate) params.append("end_date", endDate.toISOString());
      params.append("language", i18n.language);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/main_deck_type` +
          (params.toString() ? `?${params}` : ""),
      );
      if (response.ok) {
        const data = await response.json();
        setMainDeckTypeOptions(data);
      }
    };

    fetchMainDeckType();
  }, [startDate, endDate, i18n.language]);

  useEffect(() => {
    const fetchSubDeckType = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sub_deck_type/?main_deck_type=${mainDeckType}&language=${i18n.language}`,
      );
      if (response.ok) {
        const data = await response.json();
        setSubDeckTypeOptions(data);
      }
    };

    if (mainDeckType) {
      fetchSubDeckType();
    }
  }, [mainDeckType, i18n.language]);

  return (
    <Stack spacing={1}>
      <FormControlLabel
        control={
          <Select
            labelId="select-label"
            value={mainDeckType}
            onChange={(event) => {
              setMainDeckType(event.target.value);
              setSubDeckType("指定なし");
            }}
            size="small"
            fullWidth
          >
            <MenuItem value={"指定なし"} sx={{ backgroundColor: "#eeeeee" }}>
              {t("search_deck_form.not_specified")}
            </MenuItem>
            {mainDeckTypeOptions.map((option) => (
              <MenuItem value={option["name"]} key={option["name"]}>
                {option["name"]}
              </MenuItem>
            ))}
            <MenuItem value="その他" key="その他">
              {t("search_deck_form.other")}
            </MenuItem>
          </Select>
        }
        label={
          <Typography variant="caption" marginRight={1} minWidth="120px">
            {t("search_deck_form.main_deck_type")}
          </Typography>
        }
        labelPlacement="start"
        sx={{
          marginLeft: 0,
          marginRight: 0,
          width: "100%",
          maxWidth: 400,
        }}
      />
      {subDeckTypeOptions.length > 1 && (
        <FormControlLabel
          control={
            <Select
              labelId="select-label"
              value={subDeckType}
              onChange={(event) => {
                setSubDeckType(event.target.value);
              }}
              size="small"
              fullWidth
            >
              <MenuItem value={"指定なし"} sx={{ backgroundColor: "#eeeeee" }}>
                {t("search_deck_form.not_specified")}
              </MenuItem>
              {subDeckTypeOptions.map((option) => (
                <MenuItem value={option["name"]} key={option["name"]}>
                  {option["name"]}
                </MenuItem>
              ))}
              <MenuItem value="その他" key="その他">
                {t("search_deck_form.other")}
              </MenuItem>
            </Select>
          }
          label={
            <Typography variant="caption" marginRight={1} minWidth="120px">
              {t("search_deck_form.sub_deck_type")}
            </Typography>
          }
          labelPlacement="start"
          sx={{
            marginLeft: 0,
            marginRight: 0,
            width: "100%",
            maxWidth: 400,
          }}
        />
      )}
      <Box>
        <Typography variant="caption">
          {t("search_deck_form.card_conditions")}
        </Typography>
        <IconButton
          color="success"
          onClick={() =>
            setCardNameQueryList([
              ...cardNameQueryList,
              { card_names: [], card_num: 1, condition: "以上" },
            ])
          }
        >
          <AddCircleIcon />
        </IconButton>
        <Grid container spacing={2}>
          {cardNameQueryList.map((query, index) => (
            <>
              <Grid item xs={12} sm={6} lg={4}>
                <CardQueryPaper
                  key={index}
                  index={index}
                  query={query}
                  context={context}
                />
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
}
