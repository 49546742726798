import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

export default function CardQueryPaper({ index, query, context }) {
  const { t, i18n } = useTranslation(["home_page"]);

  const [newCard, setNewCard] = useState("");
  const [cardOptions, setCardOptions] = useState([]);

  const { cardNameQueryList, setCardNameQueryList } = useContext(context);

  useEffect(() => {
    const fetchCardNames = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/cards?search=${encodeURIComponent(newCard)}&language=${i18n.language}`,
        );
        const data = await response.json();
        setCardOptions(data);
      } catch (error) {
        console.error("Failed to fetch card names:", error);
      }
    };

    if (newCard.length > 0) {
      fetchCardNames();
    }
  }, [newCard, i18n]);

  const handleUpdateQuery = (index, key, value) => {
    const newQueryList = [...cardNameQueryList];
    newQueryList[index][key] = value;
    setCardNameQueryList(newQueryList);
  };

  const handleDeleteQuery = (index) => {
    const newQueryList = cardNameQueryList.filter((_, i) => i !== index);
    if (newQueryList.length === 0) {
      newQueryList.push({ card_names: [], card_num: 1, condition: "以上" });
    }
    setCardNameQueryList(newQueryList);
  };

  return (
    <Paper sx={{ padding: 2, paddingTop: 1 }} variant="outlined">
      <Stack gap={2}>
        <Autocomplete
          multiple
          options={cardOptions.map((option) => option.name)}
          onInputChange={(event, newValue) => setNewCard(newValue)}
          renderInput={(params) => <TextField {...params} variant="standard" />}
          renderTags={(value, getTagProps) => (
            <Stack
              direction="row"
              gap={0.2}
              useFlexGap
              sx={{ flexWrap: "wrap", width: "100%" }}
            >
              {value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                  }}
                />
              ))}
            </Stack>
          )}
          value={query["card_names"]}
          onChange={(event, value) =>
            handleUpdateQuery(index, "card_names", value)
          }
          fullWidth
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
          gap={2}
        >
          <Stack direction="row" gap={1}>
            {i18n.language === "ja" && (
              <Typography variant="body1" alignContent="center">
                合わせて
              </Typography>
            )}
            <Stack direction="row" gap={0.5}>
              <FormControl variant="standard" sx={{ width: 40 }}>
                <Select
                  value={query["card_num"]}
                  onChange={(event) =>
                    handleUpdateQuery(index, "card_num", event.target.value)
                  }
                  sx={{ paddingLeft: 1 }}
                >
                  {Array.from({ length: 20 }, (_, i) => (
                    <MenuItem key={i} value={i + 1}>
                      {i + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {i18n.language === "ja" && (
                <Typography variant="body2" alignContent="center">
                  枚
                </Typography>
              )}
            </Stack>
            <FormControl variant="standard">
              <Select
                labelId="condition-label-id"
                value={query["condition"]}
                onChange={(event) =>
                  handleUpdateQuery(index, "condition", event.target.value)
                }
                sx={{ paddingLeft: 1 }}
              >
                <MenuItem value="以上">
                  {t("search_deck_form.or_more")}
                </MenuItem>
                <MenuItem value="ちょうど">
                  {t("search_deck_form.exactly")}
                </MenuItem>
                <MenuItem value="以下">
                  {t("search_deck_form.or_less")}
                </MenuItem>
              </Select>
            </FormControl>
            {i18n.language === "en" && (
              <Typography variant="body1" alignContent="center">
                in total
              </Typography>
            )}
          </Stack>
          <IconButton
            onClick={() => {
              setNewCard("");
              setCardOptions([]);
              handleDeleteQuery(index);
            }}
            sx={{ paddingBottom: 0 }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Stack>
    </Paper>
  );
}
