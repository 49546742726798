import { createContext } from "react";
import React, { useState } from "react";

export const SearchDeckTabContext = createContext(null);

export function SearchDeckTabProvider({ children }) {
  const [mainDeckType, setMainDeckType] = useState("指定なし");
  const [subDeckType, setSubDeckType] = useState("指定なし");
  const [cardNameQueryList, setCardNameQueryList] = useState([
    { card_names: [], card_num: 1, condition: "以上" },
  ]);
  const [items, setItems] = useState({
    stats: [],
    decks_by_category: [],
    time_history: [],
  });
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);
  const [isBeforeSearch, setIsBeforeSearch] = useState(true);

  return (
    <SearchDeckTabContext.Provider
      value={{
        mainDeckType,
        setMainDeckType,
        subDeckType,
        setSubDeckType,
        cardNameQueryList,
        setCardNameQueryList,
        items,
        setItems,
        currentStartDate,
        setCurrentStartDate,
        currentEndDate,
        setCurrentEndDate,
        isBeforeSearch,
        setIsBeforeSearch,
      }}
    >
      {children}
    </SearchDeckTabContext.Provider>
  );
}
