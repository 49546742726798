import { createContext } from "react";
import { useState } from "react";

export const AceSpecTabContext = createContext({});

export function AceSpecTabProvider({ children }) {
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(null);

  return (
    <AceSpecTabContext.Provider
      value={{
        error,
        setError,
        items,
        setItems,
        currentStartDate,
        setCurrentStartDate,
        currentEndDate,
        setCurrentEndDate,
        currentLanguage,
        setCurrentLanguage,
      }}
    >
      {children}
    </AceSpecTabContext.Provider>
  );
}
