import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LaunchIcon from "@mui/icons-material/Launch";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";

import "../i18n";

export default function DisplayCategory({ item }) {
  const { t } = useTranslation(["home_page"]);

  const itemsPerPage = useMediaQuery("(max-width:600px") ? 2 : 3;
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(item.decks.length / itemsPerPage);

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDecks = item.decks.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{
            paddingLeft: "8px",
            borderLeft: `5px solid #FE6B8B`,
            fontWeight: "bold",
          }}
        >
          {item.category}
        </Typography>
      </Grid>
      {currentDecks.map((deck, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <Card elevation={2} sx={{ border: "1px solid #bdbdbd" }}>
            <Box position="relative">
              <Link
                to={deck.deck_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardMedia
                  component="img"
                  image={deck.thumbnail}
                  referrerPolicy="no-referrer"
                />
              </Link>
              <Box
                position="absolute"
                top={0}
                right={0}
                sx={{ padding: "4px" }}
              >
                <LaunchIcon sx={{ fontSize: 20, color: "black" }} />
              </Box>
            </Box>
            <CardContent
              style={{
                padding: "8px 8px 4px 8px",
              }}
            >
              <Grid container>
                <Grid item xs={7}>
                  <Typography variant="body1">
                    {deck.main_type} <br />
                    {deck.sub_type !== "None" && deck.sub_type} <br />
                    <Link
                      to={deck.deck_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("search_deck_tab.deck_detail")}
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1" align="right">
                    {deck.date} <br />
                    {item.category} <br />
                    <Link
                      to={deck.event_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("search_deck_tab.event_detail")}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => {
            setPage(value);
          }}
        />
      </Grid>
    </>
  );
}
