import { useEffect } from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "react-i18next";

import ClearIcon from "@mui/icons-material/Clear";
import {
  Divider,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "../pages/HomePage/i18n";

dayjs.extend(utc);

export default function DateAndStats({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dateRangeError,
  setDateRangeError,
  stats,
  setStats,
}) {
  const { t } = useTranslation(["date_and_stats"]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (dateRangeError) return;

    const fetchData = async () => {
      try {
        const params = new URLSearchParams();
        if (startDate) params.append("start_date", startDate.toISOString());
        if (endDate) params.append("end_date", endDate.toISOString());
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/stats` +
            (params.toString() ? `?${params}` : ""),
        );
        if (response.ok) {
          const data = await response.json();
          setStats(data);
        }
      } catch (e) {
        console.error("An unexpected error occurred:", e);
      }
    };

    fetchData();
  }, [startDate, endDate, dateRangeError, setStats]);

  const formatDateString = (dateStr) => {
    if (dateStr) {
      const [year, month, day] = dateStr.split("-");
      return { year, date: `${month}/${day}` };
    } else {
      return { year: "", date: "" };
    }
  };
  const formattedEarliestDate = formatDateString(stats.earliest_date);
  const formattedLatestDate = formatDateString(stats.latest_date);

  return (
    <div>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={6} sm={3}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onAccept={(newValue) => {
                setStartDate(newValue);
                if (endDate && dayjs(endDate).isBefore(dayjs(newValue))) {
                  setDateRangeError(t("date_range_error"));
                } else {
                  setDateRangeError("");
                }
              }}
              timezone="UTC"
              slotProps={{
                field: { clearable: true },
                textField:
                  isMobile && startDate
                    ? {
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setStartDate(null);
                                  setDateRangeError("");
                                }}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                      }
                    : {},
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <DatePicker
              label="End Date"
              value={endDate}
              onAccept={(newValue) => {
                setEndDate(newValue);
                if (startDate && dayjs(newValue).isBefore(dayjs(startDate))) {
                  setDateRangeError(t("date_range_error"));
                } else {
                  setDateRangeError("");
                }
              }}
              timezone="UTC"
              slotProps={{
                field: { clearable: true },
                textField:
                  isMobile && endDate
                    ? {
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setEndDate(null);
                                  setDateRangeError("");
                                }}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                      }
                    : {},
              }}
            />
          </Grid>
          {dateRangeError && (
            <Grid item xs={12}>
              <Typography color="error">{dateRangeError}</Typography>
            </Grid>
          )}
        </LocalizationProvider>
      </Grid>

      <Box variant="body2" textAlign="center">
        <Grid container marginTop={2} columnSpacing={2} rowSpacing={0.3}>
          {["date_range", "total_events", "total_results"].map((label) => (
            <Grid item xs={4} alignContent="center">
              <Typography color="grey">{t(label)}</Typography>
            </Grid>
          ))}
          {[...Array(3)].map((_, index) => (
            <Grid item xs={4}>
              <Divider color="black" />
            </Grid>
          ))}
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="center" gap={0.5}>
              <Stack direction="column">
                <Typography
                  variant="caption"
                  textAlign="left"
                  marginBottom={-0.5}
                >
                  {formattedEarliestDate.year}
                </Typography>
                {formattedEarliestDate.date}
              </Stack>
              <Box display="flex" alignItems="flex-end">
                ー
              </Box>
              <Stack direction="column">
                <Typography
                  variant="caption"
                  textAlign="left"
                  marginBottom={-0.5}
                >
                  {formattedLatestDate.year}
                </Typography>
                {formattedLatestDate.date}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {stats.event_num}
          </Grid>
          <Grid item xs={4}>
            {stats.result_num}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
