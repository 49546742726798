import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

import ChartComponent from "./ChartComponent";
import { DeckListTabContext } from "./DeckListTabContext";
import TableComponent from "./TableComponent";

export default function DeckListTab({
  startDate,
  endDate,
  dateRangeError,
  setTabValue,
  earliestDate,
  latestDate,
}) {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    error,
    setError,
    items,
    setItems,
    currentStartDate,
    setCurrentStartDate,
    currentEndDate,
    setCurrentEndDate,
    currentLanguage,
    setCurrentLanguage,
  } = useContext(DeckListTabContext);

  useEffect(() => {
    if (dateRangeError) return;
    if (
      startDate === currentStartDate &&
      endDate === currentEndDate &&
      i18n.language === currentLanguage
    )
      return;

    setLoading(true);

    const fetchData = async () => {
      try {
        const params = new URLSearchParams();
        if (startDate) params.append("start_date", startDate.toISOString());
        if (endDate) params.append("end_date", endDate.toISOString());
        params.append("language", i18n.language);
        const url =
          `${process.env.REACT_APP_API_URL}/deck_list` +
          (params.toString() ? `?${params}` : "");

        const response = await fetch(url);
        if (!response.ok) {
          const errorBody = await response.json();
          setError(
            "ERROR: " + errorBody.detail ||
              `API returned status ${response.status}`,
          );
        } else {
          const data = await response.json();
          setItems(data);
          setError(false);
        }
      } catch (e) {
        // ネットワークエラーなどの問題
        console.error("An unexpected error occurred:", e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
    setCurrentLanguage(i18n.language);
  }, [
    startDate,
    currentStartDate,
    setCurrentStartDate,
    endDate,
    currentEndDate,
    setCurrentEndDate,
    dateRangeError,
    setError,
    setItems,
    i18n.language,
    currentLanguage,
    setCurrentLanguage,
  ]);

  let content;
  if (error) {
    content = (
      <Typography variant="body1" color={"error"} marginY={2}>
        {error}
      </Typography>
    );
  } else if (loading) {
    content = (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (items.length === 0) {
    content = <p>No data found.</p>;
  } else {
    content = (
      <>
        <Grid container rowGap={2}>
          <Grid item xs={12} sm={6} order={{ xs: 1, sm: 0 }}>
            <TableComponent items={items} setTabValue={setTabValue} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartComponent
              loading={loading}
              items={items}
              earliestDate={earliestDate}
              latestDate={latestDate}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return <div>{content}</div>;
}
