import { useTranslation } from "react-i18next";

export default function CustomLabel({
  cx,
  cy,
  midAngle,
  outerRadius,
  top16_share,
  type,
  innerRadius,
  index,
  totalItems,
}) {
  const { i18n } = useTranslation();

  const RADIAN = Math.PI / 180;

  if (top16_share < 3 || index >= totalItems - 3) {
    return null;
  }

  let textLabel = null;
  let percentLabel = null;

  const radius1 = outerRadius;
  const radius2 = outerRadius + 15;
  const radius2Adjust = -270 < midAngle && midAngle < -180 ? 20 : 0;

  const textBottomX = cx + radius2 * Math.cos(-midAngle * RADIAN);
  const textBottomY =
    cy + (radius2 + radius2Adjust) * Math.sin(-midAngle * RADIAN);

  const textXOffset = 5;
  const textYOffset = 4;
  const textX =
    textBottomX > cx ? textBottomX + textXOffset : textBottomX - textXOffset;
  const textY = textBottomY - textYOffset;

  const anchorX = cx + radius1 * Math.cos(-midAngle * RADIAN);
  const anchorY = cy + radius1 * Math.sin(-midAngle * RADIAN);

  const lineEndX =
    textX > cx
      ? textX + type.length * (i18n.language === "ja" ? 10.5 : 5)
      : textX - type.length * (i18n.language === "ja" ? 10.5 : 5);

  textLabel = (
    <>
      <text
        x={textX}
        y={textY}
        fill="#424242"
        textAnchor={textX > cx ? "start" : "end"}
        alignmentBaseline="bottom"
        fontSize="10px"
      >
        {type}
      </text>
      <line
        x1={textBottomX}
        x2={lineEndX}
        y1={textBottomY}
        y2={textBottomY}
        stroke="#424242"
        strokeWidth="1"
      />
      <line
        x1={anchorX}
        y1={anchorY}
        x2={textBottomX}
        y2={textBottomY}
        stroke="#424242"
        strokeWidth="1"
      />
      <circle cx={anchorX} cy={anchorY} r="1.5" fill="#424242" />
    </>
  );

  const radius = (innerRadius + outerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  percentLabel = (
    <text
      x={x}
      y={y}
      textAnchor="middle"
      fontSize="10px"
      fill="white"
      fontWeight="bold"
      alignmentBaseline="middle"
    >
      {top16_share}%
    </text>
  );

  return (
    <>
      {textLabel}
      {percentLabel}
    </>
  );
}
