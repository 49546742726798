import React, { useState } from "react";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";

import DateAndStats from "../../components/DateAndStats";
import Layout from "../../components/Layout";
import AceCardTab from "./AceCardTab/AceCardTab";
import { AceSpecTabProvider } from "./AceCardTab/AceSpecTabContext";
import CardUsageRateTab from "./CardUsageRateTab/CardUsageRateTab";
import { CardUsageRateTabProvider } from "./CardUsageRateTab/CardUsageRateTabContext";
import DeckListTab from "./DeckListTab/DeckListTab";
import { DeckListTabProvider } from "./DeckListTab/DeckListTabContext";
import SearchDeckTab from "./SearchDeckTab/SearchDeckTab";
import { SearchDeckTabProvider } from "./SearchDeckTab/SearchDeckTabContext";
import "./i18n";

export default function HomePage() {
  const { t } = useTranslation(["home_page"]);

  const [startDate, setStartDate] = useState(dayjs.utc("2025-01-24"));
  const [endDate, setEndDate] = useState(null);
  const [dateRangeError, setDateRangeError] = useState("");
  const [stats, setStats] = useState("");
  const [tabValue, setTabValue] = useState("1");

  return (
    <DeckListTabProvider>
      <AceSpecTabProvider>
        <CardUsageRateTabProvider>
          <SearchDeckTabProvider>
            <Layout>
              <DateAndStats
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dateRangeError={dateRangeError}
                setDateRangeError={setDateRangeError}
                stats={stats}
                setStats={setStats}
              />

              <Box sx={{ width: "100%", typography: "body1", marginTop: 1 }}>
                <TabContext value={tabValue}>
                  <Box sx={{ borderColor: "divider" }}>
                    <TabList
                      onChange={(event, newValue) => {
                        setTabValue(newValue);
                      }}
                      variant="scrollable"
                    >
                      <Tab
                        label={t("deck_list")}
                        value="1"
                        style={{
                          fontWeight: "bold",
                          paddingLeft: 0,
                          paddingRight: 10,
                        }}
                      />
                      <Tab
                        label={t("ace_spec")}
                        value="2"
                        style={{
                          fontWeight: "bold",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      />
                      <Tab
                        label={t("card_usage_rate")}
                        value="3"
                        style={{
                          fontWeight: "bold",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      />
                      <Tab
                        label={t("search_deck")}
                        value="4"
                        style={{
                          fontWeight: "bold",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      />
                    </TabList>
                  </Box>

                  <TabPanel value="1" sx={{ padding: 0, paddingTop: 1 }}>
                    <DeckListTab
                      startDate={startDate}
                      endDate={endDate}
                      dateRangeError={dateRangeError}
                      setTabValue={setTabValue}
                      earliestDate={stats.earliest_date}
                      latestDate={stats.latest_date}
                    />
                  </TabPanel>
                  <TabPanel value="2" sx={{ padding: 0, paddingTop: 3 }}>
                    <AceCardTab
                      startDate={startDate}
                      endDate={endDate}
                      dateRangeError={dateRangeError}
                      setTabValue={setTabValue}
                    />
                  </TabPanel>
                  <TabPanel value="3" sx={{ padding: 0, paddingTop: 3 }}>
                    <CardUsageRateTab
                      startDate={startDate}
                      endDate={endDate}
                      dateRangeError={dateRangeError}
                      setTabValue={setTabValue}
                    />
                  </TabPanel>
                  <TabPanel value="4" sx={{ padding: 0, paddingTop: 3 }}>
                    <SearchDeckTab
                      startDate={startDate}
                      endDate={endDate}
                      dateRangeError={dateRangeError}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </Layout>
          </SearchDeckTabProvider>
        </CardUsageRateTabProvider>
      </AceSpecTabProvider>
    </DeckListTabProvider>
  );
}
