import { useState } from "react";
import { createContext } from "react";

export const CardUsageRateTabContext = createContext(null);

export function CardUsageRateTabProvider({ children }) {
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);
  const [cardName, setCardName] = useState([]);
  const [currentCardName, setCurrentCardName] = useState("");
  const [isBeforeSearch, setIsBeforeSearch] = useState(true);

  return (
    <CardUsageRateTabContext.Provider
      value={{
        error,
        setError,
        items,
        setItems,
        currentStartDate,
        setCurrentStartDate,
        currentEndDate,
        setCurrentEndDate,
        cardName,
        setCardName,
        currentCardName,
        setCurrentCardName,
        isBeforeSearch,
        setIsBeforeSearch,
      }}
    >
      {children}
    </CardUsageRateTabContext.Provider>
  );
}
