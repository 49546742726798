import React from "react";

import { useTranslation } from "react-i18next";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Dot,
  Legend,
  Line,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { useMediaQuery } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { blueGrey, deepOrange } from "@mui/material/colors";

import "../i18n";

export default function GraphComponent({ data }) {
  const { t } = useTranslation(["home_page"]);

  const isXs = useMediaQuery("(max-width:600px)");

  function CustomLabel({ x, y, value, position, totalItems, deckTypeValue }) {
    if (
      totalItems > 7 ||
      (deckTypeValue !== undefined && deckTypeValue === value)
    ) {
      return null;
    }

    return (
      <text
        x={x}
        y={y}
        dy={position === "top" ? -5 : 15}
        fill="gray"
        fontSize={12}
        textAnchor="middle"
      >
        {value != null ? `${value.toFixed(1)}%` : ""}
      </text>
    );
  }

  function CustomDot(props) {
    if (props.totalItems > 7) {
      return <Dot {...props} r={0} />;
    }
    return (
      <Dot {...props} stroke={props.stroke} strokeWidth={2} fill="white" />
    );
  }

  const charts = [
    {
      title: t("search_deck_tab.graph1_title"),
      component: (
        <ResponsiveContainer width="100%" height={200}>
          <ComposedChart data={data} margin={{ top: 20, right: 20 }}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="date"
              tick={{ fontSize: 14 }}
              padding={{ left: 20 }}
            />
            <YAxis unit="%" width={50} padding={{ bottom: 20 }} />
            <Line
              dataKey="deck_type_ratio_all"
              stroke={deepOrange[600]}
              strokeWidth={2}
              connectNulls={true}
              name={t("search_deck_tab.graph1_label1")}
              label={(props) => (
                <CustomLabel
                  {...props}
                  position="top"
                  totalItems={data.length}
                />
              )}
              animationDuration={700}
              strokeDasharray="5 5"
              dot={(props) => <CustomDot {...props} totalItems={data.length} />}
            />
            <Line
              dataKey="search_match_ratio_all"
              stroke={blueGrey[600]}
              strokeWidth={2}
              connectNulls={true}
              name={t("search_deck_tab.graph1_label2")}
              label={(props) => (
                <CustomLabel
                  {...props}
                  position="bottom"
                  totalItems={data.length}
                  deckTypeValue={data[props.index]?.deck_type_ratio_all}
                />
              )}
              animationDuration={700}
              dot={(props) => <CustomDot {...props} totalItems={data.length} />}
            />
            <Legend align="right" wrapperStyle={{ fontSize: "12px" }} />
          </ComposedChart>
        </ResponsiveContainer>
      ),
    },
    {
      title: t("search_deck_tab.graph2_title"),
      component: (
        <ResponsiveContainer width="100%" height={200}>
          <ComposedChart data={data} margin={{ top: 20 }} barSize="5%">
            <CartesianGrid stroke="#f5f5f5" />
            <Bar
              dataKey="search_match_ratio_deck"
              fill={blueGrey[300]}
              background={({ search_match_ratio_deck, x, y, width, height }) =>
                search_match_ratio_deck != null && (
                  <Rectangle
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill="#eeeeee"
                  />
                )
              }
              legendType="none"
              animationDuration={700}
            />
            <XAxis dataKey="date" tick={{ fontSize: 14 }} />
            <YAxis domain={[0, 100]} unit="%" width={50} />
            <Legend align="right" wrapperStyle={{ fontSize: "12px" }} />
          </ComposedChart>
        </ResponsiveContainer>
      ),
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Swiper
        modules={[Navigation]}
        navigation
        spaceBetween={0}
        slidesPerView={isXs ? 1 : 2}
      >
        {charts.map((item, index) => (
          <SwiperSlide>
            <Box paddingX={2}>
              <Typography variant="body1" align="center">
                {item.title}
              </Typography>
              <Box>{item.component}</Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <style>
        {`
          .swiper-button-prev,
          .swiper-button-next {
            height: 30px;
          }
          .swiper-button-prev {
            left: 0;
            justify-content: flex-start;
          }
          .swiper-button-next {
            right: 0;
            justify-content: flex-end;
          }
          .swiper-button-prev::after,
          .swiper-button-next::after {
            font-size: 20px;
          }
        `}
      </style>
    </Box>
  );
}
