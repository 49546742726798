import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { SearchDeckTabContext } from "../SearchDeckTab/SearchDeckTabContext";
import "../i18n";

export default function TableComponent({
  mainDeckType,
  subDeckType,
  items,
  setTabValue,
  currentCardName,
}) {
  const { t } = useTranslation(["home_page"]);

  const { setMainDeckType, setSubDeckType, setCardNameQueryList } =
    useContext(SearchDeckTabContext);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" sx={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow key="Head">
            <TableCell sx={{ maxWidth: 300 }}>
              {t("card_usage_rate_tab.usage")}
            </TableCell>
            <TableCell sx={{ paddingLeft: 0 }}>
              {t("card_usage_rate_tab.rate")}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {items.map((row, index) => (
            <TableRow
              key={row.type}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ maxWidth: 300 }}>
                <Link
                  onClick={() => {
                    let card_num = row.label
                      .replace("枚", "")
                      .replace("以上", "");
                    if (card_num !== "0") {
                      setMainDeckType(mainDeckType);
                      if (subDeckType === "None") {
                        setSubDeckType("指定なし");
                      } else if (subDeckType.includes("その他")) {
                        setSubDeckType("その他");
                      } else {
                        setSubDeckType(subDeckType);
                      }
                      setCardNameQueryList([
                        {
                          card_names: [currentCardName],
                          card_num: card_num,
                          condition: "以上",
                        },
                      ]);
                      setTabValue("4");
                    }
                  }}
                >
                  {row.label
                    .replace("枚", t("card_usage_rate_tab.counter_word"))
                    .replace("以上", t("card_usage_rate_tab.or_more"))}
                </Link>
              </TableCell>
              <TableCell sx={{ paddingLeft: 0, position: "relative" }}>
                {row.rate}%
                <LinearProgress
                  variant="determinate"
                  value={row.rate}
                  sx={{
                    height: 5,
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
