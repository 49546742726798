import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import SearchDeckFormComponent from "../components/SearchDeckFormComponent";
import "../i18n";
import i18n from "../i18n";
import DisplayCategory from "./DisplayCategory";
import GraphComponent from "./GraphComponent";
import { SearchDeckTabContext } from "./SearchDeckTabContext";

export default function SearchDeckTab({ startDate, endDate, dateRangeError }) {
  const { t } = useTranslation(["home_page"]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    mainDeckType,
    subDeckType,
    cardNameQueryList,
    items,
    setItems,
    currentStartDate,
    setCurrentStartDate,
    currentEndDate,
    setCurrentEndDate,
    isBeforeSearch,
    setIsBeforeSearch,
  } = useContext(SearchDeckTabContext);

  useEffect(() => {
    if (dateRangeError) return;
    if (startDate === currentStartDate && endDate === currentEndDate) return;

    setItems([]);
    setIsBeforeSearch(true);
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
  }, [
    startDate,
    currentStartDate,
    setCurrentStartDate,
    endDate,
    currentEndDate,
    setCurrentEndDate,
    dateRangeError,
    setItems,
    setIsBeforeSearch,
  ]);

  const fetchData = async () => {
    if (dateRangeError) return;

    setLoading(true);

    try {
      const params = new URLSearchParams();
      if (startDate) params.append("start_date", startDate.toISOString());
      if (endDate) params.append("end_date", endDate.toISOString());
      if (mainDeckType && mainDeckType !== "指定なし")
        params.append("main_deck_type", mainDeckType);
      if (subDeckType && subDeckType !== "指定なし")
        params.append("sub_deck_type", subDeckType);
      params.append("card_name_query_list", JSON.stringify(cardNameQueryList));
      params.append("language", i18n.language);

      const url =
        `${process.env.REACT_APP_API_URL}/search_deck` +
        (params.toString() ? `?${params}` : "");

      const response = await fetch(url);
      if (!response.ok) {
        const errorBody = await response.json();
        setError(
          "ERROR: " + errorBody.detail ||
            `API returned status ${response.status}`,
        );
        setItems({
          stats: {},
          decks_by_category: [],
        });
      } else {
        setError(null);
        const data = await response.json();
        setItems(data);
        setIsBeforeSearch(false);
      }
    } catch (e) {
      console.error("An unexpected error occured: ", e);
    } finally {
      setLoading(false);
    }
  };

  let content;
  if (error) {
    content = (
      <Typography varient="body1" color={"error"} marginY={2}>
        {error}
      </Typography>
    );
  } else if (loading) {
    content = (
      <Box sx={{ display: "flex", justifyContent: "center" }} width="100%">
        <CircularProgress />
      </Box>
    );
  } else if (isBeforeSearch) {
    content = null;
  } else {
    content = (
      <Stack rowGap={3}>
        <GraphComponent data={items.time_history} />
        {items.is_result_num_clipped && (
          <Alert severity="info">
            {t("search_deck_tab.too_many_deck_alert")}
          </Alert>
        )}
        <Grid container spacing={2}>
          {items.decks_by_category.map((item, index) => (
            <DisplayCategory item={item} key={index} />
          ))}
        </Grid>
      </Stack>
    );
  }

  return (
    <>
      <Grid container spacing={2} sx={{ marginBottom: { xs: 4, sm: 2 } }}>
        <Grid item xs={12} sm={11}>
          <SearchDeckFormComponent
            startDate={startDate}
            endDate={endDate}
            context={SearchDeckTabContext}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            variant="contained"
            color="button"
            fullWidth
            sx={{
              height: "100%",
            }}
            onClick={() => {
              fetchData();
            }}
          >
            <SearchIcon />
          </Button>
        </Grid>
      </Grid>
      {!useMediaQuery("(max-width:600px)") && (
        <Divider sx={{ marginBottom: 3 }} color={blueGrey[200]} />
      )}
      {content}
    </>
  );
}
