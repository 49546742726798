import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import "../i18n";
import i18n from "../i18n";
import { CardUsageRateTabContext } from "./CardUsageRateTabContext";
import TableComponent from "./TableComponent";

export default function CardUsageRateTab({
  startDate,
  endDate,
  dateRangeError,
  setTabValue,
}) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["home_page"]);

  const [cardOptions, setCardOptions] = useState([]);
  const {
    error,
    setError,
    items,
    setItems,
    currentStartDate,
    setCurrentStartDate,
    currentEndDate,
    setCurrentEndDate,
    cardName,
    setCardName,
    currentCardName,
    setCurrentCardName,
    isBeforeSearch,
    setIsBeforeSearch,
  } = useContext(CardUsageRateTabContext);

  useEffect(() => {
    const fetchCardNames = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/cards?search=${encodeURIComponent(cardName)}&language=${i18n.language}`,
        );
        const data = await response.json();
        setCardOptions(data);
      } catch (error) {
        console.error("Failed to fetch card names:", error);
      }
    };

    if (cardName.length > 0) {
      fetchCardNames();
    }
  }, [cardName]);

  useEffect(() => {
    if (dateRangeError) return;
    if (startDate === currentStartDate && endDate === currentEndDate) return;

    setItems([]);
    setIsBeforeSearch(true);
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
  }, [
    startDate,
    currentStartDate,
    setCurrentStartDate,
    endDate,
    currentEndDate,
    setCurrentEndDate,
    dateRangeError,
    setItems,
    setIsBeforeSearch,
  ]);

  const fetchData = async () => {
    if (dateRangeError) return;

    setLoading(true);

    try {
      const params = new URLSearchParams();
      if (startDate) params.append("start_date", startDate.toISOString());
      if (endDate) params.append("end_date", endDate.toISOString());
      if (cardName) params.append("card_name", cardName);
      const url =
        `${process.env.REACT_APP_API_URL}/card_usage_rate` +
        (params.toString() ? `?${params}` : "");

      const response = await fetch(url);
      if (!response.ok) {
        const errorBody = await response.json();
        setError(
          "ERROR: " + errorBody.detail ||
            `API returned status ${response.status}`,
        );
      } else {
        const data = await response.json();
        setItems(data);
        setError(false);
        setIsBeforeSearch(false);
      }
    } catch (e) {
      // ネットワークエラーなどの問題
      console.error("An unexpected error occurred:", e);
    } finally {
      setLoading(false);
    }
  };

  let content;
  if (error) {
    if (error === "ERROR: card_nameを指定してください") {
      content = (
        <Typography varient="body1" color={"error"} marginY={2}>
          {t("card_usage_rate_tab.no_card_name_error")}
        </Typography>
      );
    } else {
      content = (
        <Typography varient="body1" color={"error"} marginY={2}>
          {error}
        </Typography>
      );
    }
  } else if (loading) {
    content = (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (isBeforeSearch) {
    content = null;
  } else if (items.length === 0) {
    content = <p>No data found.</p>;
  } else {
    content = (
      <>
        <Grid container spacing={4}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                {item.sub_deck_type !== "None"
                  ? `${item.main_deck_type} / ${item.sub_deck_type}`
                  : item.main_deck_type}
              </Typography>
              <Divider
                color={blueGrey[600]}
                sx={{ height: 2, marginBottom: 1 }}
              />
              <Typography
                variant="body1"
                marginBottom={1}
                textAlign="right"
                color={blueGrey[800]}
              >
                {t("card_usage_rate_tab.result_num")}: {item.total_result_num}
              </Typography>
              <Box width="100%" display="flex" justifyContent="center">
                <Box maxWidth="80%">
                  <TableComponent
                    mainDeckType={item.main_deck_type}
                    subDeckType={item.sub_deck_type}
                    items={item.usage_rates}
                    setTabValue={setTabValue}
                    currentCardName={currentCardName}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={8} sm={4}>
        <Autocomplete
          options={cardOptions.map((option) => option.name)}
          onInputChange={(event, newValue) => {
            setCardName(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Card Name" fullWidth />
          )}
          value={cardName}
        />
      </Grid>
      <Grid item xs={4} sm={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            fetchData();
            setCurrentCardName(cardName);
          }}
        >
          {t("card_usage_rate_tab.search")}
        </Button>
      </Grid>

      <Grid item xs={12}>
        {content}
      </Grid>
    </Grid>
  );
}
